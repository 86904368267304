import Button from '@/components/UI/Button';
import Dropdown from '@/components/UI/Dropdown/Dropdown';
import React, { useEffect, useState } from 'react'
import { defaultState, defaultTypeOfRevenue } from '../DetailConstant';
import { formatNumberWithCommas, removeCommas,formatInputNumberWithCommas } from '@/utils/commanFunctions';
import { revenueGeneratingAssetArray } from "@/constants/getCapitalConstants";
import Loader from '@/components/Loader';
import { updateAssetsById, updateProjectDetails } from '@/services/api';
import ConfirmationModal from '../ConfirmationModal';
import { FaInfoCircle } from 'react-icons/fa';
import { string } from 'zod';


type DefaultStateKeys = keyof typeof defaultState;


const CustomTooltip = ({ content }: { content: string }) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <div className="relative inline-block">
            <FaInfoCircle
                className="text-gray-400 cursor-pointer ml-1"
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            />
            {isVisible && (
                <div className="min-w-[200px] absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm tooltip dark:bg-gray-700 bottom-full left-20 -translate-x-[70%] mb-2 w-48">
                    {content}
                    <div className="tooltip-arrow" data-popper-arrow></div>
                </div>
            )}
        </div>
    );
};

const LabelWithTooltip = ({ label, tooltip }: { label: string; tooltip: string }) => (
    <div className="label flex items-center">
        {label}
        <CustomTooltip content={tooltip} />
    </div>
);

const AssetDetails = ({ data, refetch, isInReviewForListing }: any) => {

    const {
        revenueStreams = [],
        assetIds = [],
    } = { ...data };
    const { model = "", brand = "", assetType = "", technology = "", powerCapacity = "", serialNumber = "" } = { ...assetIds[0] };

    const [isEditMode, setIsEditMode] = useState({
        assetIds: false,
    });
    const [details, setDetail] = useState({
        assetIds: [
            {
                model: "",
                brand: "",
                assetType: "",
                technology: "",
                powerCapacity: "",
                serialNumber: "",
            },
        ],
    });
    const [isUpdating, setUpdating] = useState(false);
    const [typeOfRevenue, setTypeOfRevenue] = useState(defaultTypeOfRevenue);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        setDetail({
            assetIds: [
                {
                    model,
                    brand,
                    assetType,
                    technology,
                    powerCapacity,
                    serialNumber,
                },
            ],
        });

        setTypeOfRevenue((prevState: any) => {
            return prevState.map((item: any) => {
                const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
                if (hasStream) {
                    return {
                        ...item,
                        name: hasStream.name,
                        status: true,
                        yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
                        projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
                    };
                }
                return item;
            });
        });
        // eslint-disable-next-line
    }, [data]);


    const handleChangeDetails = async (evt: any, changingTo: any) => {
        const { name, value } = evt.target;

        // List of names to check against
        const namesToCheck = [
            "operationLifetime",
            "totalInitialCost",
            "annualOperationCosts",
            "npv",
            "irr",
            "lcoe",
            "paybackPeriod",
            "capacityFactor",
            "fundingGoal",
            "tokenPrice",
            "tokenAmount",
            "contractTerm",
            "amount",
        ];

        // Replace commas only if the name is in the list
        const processedValue = namesToCheck.includes(name) && typeof value === "string" ? removeCommas(value) : value;

        if (!isUpdating) {
            setDetail((prevState: any) => {
                return {
                    ...prevState,
                    [changingTo]: [
                        {
                            ...prevState[changingTo][0],
                            [name]: processedValue,
                        },
                    ],
                };
            });
        }
    };
    const handleClickCancleOrEdit = async (clickedTo: DefaultStateKeys) => {
        setIsEditMode((prevState: any) => ({ ...prevState, [clickedTo]: !prevState[clickedTo] }));
        setDetail((prevState: any) => {
            return {
                ...prevState,
                [clickedTo]: {
                    ...(data[clickedTo]
                        ? data[clickedTo]
                        : defaultState[clickedTo]),
                },
            };
        });
        setTypeOfRevenue(() => {
            return defaultTypeOfRevenue.map((item: any) => {
                const hasStream = revenueStreams.find((ele: any) => ele.type === item.value);
                if (hasStream) {
                    return {
                        ...item,
                        name: hasStream.name,
                        status: true,
                        yearlyOrMonthly: hasStream.monthlyRevenue ? "0" : "1",
                        projectedRevenue: hasStream.monthlyRevenue || hasStream.yearlyRevenue,
                    };
                }
                return { ...item, status: false };
            });
        });
    };

    const handleUpdateDetails = async (updatingTo: DefaultStateKeys) => {
        setUpdating(true);
        if (updatingTo === "assetIds") {
            await updateAssetsById(data.assetIds[0]._id, details[updatingTo][0]);
            await refetch();
            return;
        }

        const updatedDetails = { ...data, ...details['assetIds'] };
        await updateProjectDetails(data._id, updatedDetails);
        await refetch();
        setUpdating(false);
        setShowConfirmModal(false)
    };

    const handleSaveClick = () => {
        setShowConfirmModal(true);
    };

    return (
        <>
            <div className="general-card-section">
                <h2 className="text-[14px]">Asset</h2>
                <p className="text-[11px] ">Technical Specifications</p>
            </div>
            <div className="vertical-line"></div>
            <div className="general-card-section text-[11px]">
                <div className="flex flex-wrap items-center justify-between">
                    <div className="field w-[100%] ">
                        <LabelWithTooltip
                            label="Revenue generating asset used to pay back loan"
                            tooltip="Select the type of asset that generates revenue for loan repayment"
                        />
                        <Dropdown
                            menuBoxMarginTop={5}
                            disabled={!isEditMode.assetIds}
                            value={details.assetIds[0].assetType}
                            className={`${isEditMode.assetIds ? "border rounded px-2" : "border-t-0 border-l-0 border-r-0 border-b border-monochrome-10 !rounded-none"
                                } px-0 w-full text-ternary text-sm`}
                            onChange={(selectedValue: any) => {
                                const evt = { target: { name: "assetType", value: selectedValue.value } };
                                handleChangeDetails(evt, "assetIds");
                            }}
                            options={revenueGeneratingAssetArray}
                        />
                    </div>
                    <div className="field w-[100%] ">
                        <LabelWithTooltip
                            label="Power capacity (kw)"
                            tooltip="Enter the power capacity of the asset in kilowatts"
                        />
                        <input
                            name="powerCapacity"
                            disabled={!isEditMode.assetIds}
                            value={
                                isEditMode.assetIds
                                    ?  formatNumberWithCommas(String(details.assetIds[0].powerCapacity))
                                    : `${formatNumberWithCommas(String(details.assetIds[0].powerCapacity))} kW`
                            }
                            onChange={(evt) => handleChangeDetails(evt, "assetIds")}
                            type="text"
                            placeholder="150,000 kW"
                            className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                        />

                    </div>
                </div>
                <div className="field">
                    <LabelWithTooltip
                        label="Technology"
                        tooltip="Specify the technology used in the asset"
                    />
                    <input
                        type="text"
                        disabled={!isEditMode.assetIds}
                        value={details.assetIds[0].technology}
                        name="technology"
                        onChange={(evt) => handleChangeDetails(evt, "assetIds")}
                        placeholder="Enter the technology used"
                        className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                    />
                </div>
                <div className="field">
                    <LabelWithTooltip
                        label="Brand"
                        tooltip="Enter the brand of the asset"
                    />
                    <input
                        disabled={!isEditMode.assetIds}
                        value={details.assetIds[0].brand}
                        type="text"
                        name="brand"
                        onChange={(evt) => handleChangeDetails(evt, "assetIds")}
                        placeholder="Enter the brand used"
                        className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                    />
                </div>
                <div className="field">
                    <LabelWithTooltip
                        label="Model"
                        tooltip="Specify the model of the asset"
                    />
                    <input
                        disabled={!isEditMode.assetIds}
                        value={details.assetIds[0].model}
                        type="text"
                        name="model"
                        onChange={(evt) => handleChangeDetails(evt, "assetIds")}
                        placeholder="Enter the model used"
                        className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                    />
                </div>
                <div className="field">
                    <LabelWithTooltip
                        label="Serial Number"
                        tooltip="Enter the unique serial number of the asset"
                    />
                    <input
                        disabled={!isEditMode.assetIds}
                        value={details.assetIds[0].serialNumber}
                        type="text"
                        name="serialNumber"
                        onChange={(evt) => handleChangeDetails(evt, "assetIds")}
                        placeholder="Enter the serial number"
                        className={`${isEditMode.assetIds ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"} px-0 w-full text-ternary text-sm`}
                    />
                </div>
                <div className="flex gap-4 justify-end">
                    {!isInReviewForListing && (
                        <Button primary rounded type="button" classNames="h-10" onClick={() => handleClickCancleOrEdit("assetIds")}>
                            {isEditMode.assetIds ? "Cancel" : "Edit"}
                        </Button>
                    )}
                    {isEditMode.assetIds && (
                        <Button disabled={isUpdating} secondary rounded classNames={`h-10`} onClick={handleSaveClick}>
                            {isUpdating ? <Loader isLoading={true} /> : "Save"}
                        </Button>
                    )}
                </div>
            </div>

            <ConfirmationModal
                show={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={() => handleUpdateDetails("assetIds")}
                message="Are you sure you want to update the asset details of your project?"
            />
        </>
    )
}

export default AssetDetails